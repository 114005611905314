import React from 'react';
import Thumb from '@/components/thumb/Thumb';
import Translate from '@/components/i18n/Translate';
import parseHtml from '@/utils/parseHtml';
import { string, object } from 'prop-types';
import './GalleryCTA.scss';

/**
 * @function GalleryCTA
 * @description creates a CTA slide placed at the end of a gallery
 * @param {String} title the title of the gallery
 * @param {Object} thumbnail the CTA thumbnail object
 * @param {String} uri the url path to the new gallery
 * @return {React.ReactElement}
 */
const GalleryCTA = ({ title, thumbnail, uri }) => (
	<a className="gallery__item gallery__item--cta" href={uri} data-tb-region-item>
		<div className="gallery__cta-prompt">
			<Translate word="NEXT_GALLERY" />:
		</div>
		<span className="gallery__cta-title" data-hook="gallery-cta-title">
			{parseHtml(title)}
		</span>
		<Thumb thumbnail={thumbnail} title={title} withLink={false} width="130" height="130" />
	</a>
);

GalleryCTA.propTypes = {
	title: string.isRequired,
	thumbnail: object.isRequired,
	uri: string.isRequired
};

GalleryCTA.displayName = 'GalleryCTA';

export default GalleryCTA;
