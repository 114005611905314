import React, { useRef, useEffect } from 'react';
import MpsAd from '@/components/mps/MpsAd';
import { refreshSlot } from '@/components/mps/mps';
import { bool, object, string } from 'prop-types';
import './GalleryInterstitialAd.scss';

/**
 * @function GalleryInterstitialAd
 * @description Interstitial ad slide in gallery widget
 * @param {Object} props
 * @param {Boolean} props.active whether the slide is active and should be shown
 * @param {Boolean} props.isSlideBeforeInterstitial whether its the slide before the interstital should render
 * @param {Object|Null} [props.style] the style properties to apply to the ad slide
 * @param {String|null} [props.widgetAdKeyword] ad keyword
 * @return {React.ReactElement}
 */
const GalleryInterstitialAd = ({ active, style, widgetAdKeyword, isSlideBeforeInterstitial }) => {
	const isInitialRender = useRef(true);
	useEffect(() => {
		if (!isSlideBeforeInterstitial) return;
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}
		// Refresh on the slide before we get to the interstital so the ad is already rendered when we slide into view. (Better UX)
		refreshSlot('interstitial');
	}, [isSlideBeforeInterstitial]);

	return (
		<div
			className={`gallery__ad ${active ? 'gallery__ad--active' : ''}`}
			style={style}
			data-hook="gallery-ad"
		>
			<div className="gallery__ad-content" data-hook="gallery-interstitial-ad">
				<MpsAd slot="interstitial" keywords={widgetAdKeyword} centered repeatable />
			</div>
		</div>
	);
};

GalleryInterstitialAd.propTypes = {
	active: bool.isRequired,
	style: object,
	widgetAdKeyword: string,
	isSlideBeforeInterstitial: bool.isRequired
};

GalleryInterstitialAd.displayName = 'GalleryInterstitialAd';

export default GalleryInterstitialAd;
