import React, { useState, useEffect, useRef, Fragment } from 'react';
import throttle from 'lodash-es/throttle';
import unionBy from 'lodash-es/unionBy';
import useInterval from '@use-it/interval';
import chevronThin from '@/images/chevron-thin.svg';
import SvgImage from '@/components/icons/SvgImage';
import parseHtml from '@/utils/parseHtml';
import GalleryInterstitialAd from '@/components/ads/GalleryInterstitialAd';
import GallerySlideImg from './GallerySlideImg';
import GalleryCTA from './GalleryCTA';
import WidgetDate from '@/components/widgets/WidgetDate';
import WidgetAuthor from '@/components/widgets/WidgetAuthor';
import customEventDispatcher from '@/components/analytics/customEventDispatcher';
import { activeClassNames, inactiveClassNames } from './galleryHelpers.js';
import { bool, number, array, string, func, object } from 'prop-types';
import './GallerySlider.scss';

// static constants for reference
const sliceWidth = 150;
const sliceMargin = 10;
const thumbnailHeight = 600;
const adFrequency = 7;
const titleLineHeight = 26;
const descriptionLineHeight = 22; // slightly larger than actual line height to account for <p> margins

/**
 * @function GallerySlider
 * @description creates the slider component
 * @param {Object} props
 * @param {Array} props.nodes the gallery data
 * @param {Number} props.totalCount the amount of items in the gallery
 * @param {Boolean} [props.hasCover] whether a coverflow image is present
 * @param {String} [props.alignment] whether to left or center align images
 * @param {Function} props.fetchMore query callback to fetch more images from GQL
 * @param {String|null} [props.widgetAdKeyword] data for ad content (TBD on usage until ads are fully implemented)
 * @param {Object|null} [props.nextGalleryInfo] gallery info for the CTA at the end of the current gallery
 * @param {String|null} [props.analyticsData] tracking data
 * @param {Object|null} [props.dataLayer] dataLayer object for analytics page view tracking
 * @param {Boolean} props.disableLazyLoadOnFirstImg Whether the first slider img should not be lazy loaded (improves LCP)
 * @return {React.ReactElement}
 */
const GallerySlider = ({
	nodes,
	totalCount,
	hasCover,
	alignment,
	fetchMore,
	widgetAdKeyword,
	nextGalleryInfo,
	authors,
	publishDate,
	authorBylineEnabled,
	timestampsEnabled,
	dataLayer,
	analyticsData,
	disableLazyLoadOnFirstImg
}) => {
	const [activeSlide, setActiveSlide] = useState(0);
	const [left, setLeft] = useState(0);
	const [expanded, setExpanded] = useState(false);
	const [maxWidth, setMaxWidth] = useState(1044);
	const [containerWidth, setContainerWidth] = useState(3000);
	const [showExpandButton, setShowExpandButton] = useState(false);
	const [multilineDescription, setMultilineDescription] = useState(false);
	const [adActive, setAdActive] = useState(false);
	const [adSlideStyle, setAdSlideStyle] = useState({ left: 0 });
	const [galleryItems, updateGalleryItems] = useState(nodes);
	const [totalImgCount, setTotalImgCount] = useState(totalCount);
	const [pageviewTimeout, setPageviewTimeout] = useState(null);
	const wrapperEl = useRef(null);
	const titleRefs = useRef([]);
	const descriptionRefs = useRef([]);
	const numOfClicks = useRef(0);
	const updateSlideReference = useRef(-1);
	const galleryPageviewEvent = useRef(null);
	const scaledWidth = [];
	const hasNextGallery = Boolean(nextGalleryInfo?.uri);

	useEffect(() => {
		// the maximum width an image can be is the container width minus prev/next buttons width
		setMaxWidth(wrapperEl.current?.offsetWidth - 60);
		// set the expand arrow state of the first slide
		if (activeSlide === 0) expandButton(0);
	}, [wrapperEl]);

	useEffect(() => {
		// check if the active slide needs to be updated after loading / refreshing gallery items
		if (updateSlideReference.current >= 0) {
			updateSlide(updateSlideReference.current, true);
			updateSlideReference.current = -1;
		}
	}, [galleryItems]);

	useEffect(() => {
		if (alignment === 'center') {
			// make sure image is still center aligned if the gallery width changes
			calcLeft(activeSlide);
		}
		// recalculate if the expand buttons should show up
		expandButton(activeSlide);
	}, [maxWidth]);

	useEffect(() => {
		if (galleryItems) {
			// create a reference for the custom virtual page view analytics event
			galleryPageviewEvent.current = customEventDispatcher(
				'arrivals-gallery-virtual-pageview'
			);
			// set the width of the images container based on the amount of image slices
			// plus an extra slice space for the CTA
			// plus extra space for a fully open image
			const imgsWidth = (galleryItems.length + 1) * (sliceWidth + sliceMargin) + 1044;
			setContainerWidth(imgsWidth);

			const resizeListener = throttle(updateLayout, 100);
			window.addEventListener('resize', resizeListener);
			return () => window.removeEventListener('resize', resizeListener);
		}
	}, []);

	// check for new images every 30 seconds
	useInterval(() => {
		loadMoreImgs(true);
	}, 30000);

	// if checkForUpdates is set to "true"
	// then just check to see if more images have been added to the gallery
	const loadMoreImgs = (checkForUpdates = false) => {
		const skip = checkForUpdates ? 0 : galleryItems.length;

		fetchMore({
			variables: {
				skip: skip
			}
		}).then((fetchMoreResult) => {
			if (fetchMoreResult) {
				let newGalleryItems;
				const nodes =
					fetchMoreResult?.data?.gallery?.galleryitems?.nodes ||
					fetchMoreResult?.data?.galleryFolder?.galleryItems?.nodes;
				// merge old image data with new fetched, making sure there are no duplicates
				if (checkForUpdates) {
					// if checking for updates, add on results to the beginning of the gallery
					newGalleryItems = unionBy(nodes, galleryItems, 'image.id');
				} else {
					// if pagination, add new results to the end of the gallery
					newGalleryItems = unionBy(galleryItems, nodes, 'image.id');
				}

				const newTotal =
					fetchMoreResult?.data?.gallery?.galleryitems?.totalCount ||
					fetchMoreResult?.data?.galleryFolder?.galleryItems?.totalCount ||
					0;
				const currImgId = galleryItems[activeSlide]?.image?.id;

				if (currImgId !== newGalleryItems[activeSlide]?.image?.id) {
					// something has changed in the gallery
					// so we need to make sure to update the active slide index
					for (let i = 0; i < newGalleryItems.length; i++) {
						if (currImgId === newGalleryItems[i].image.id) {
							updateSlideReference.current = i;
						}
					}
				}

				// update the gallery total image count if it changed
				if (newTotal !== totalImgCount) {
					setTotalImgCount(newTotal);
				}

				updateGalleryItems(newGalleryItems);
				// update container width with new image amount
				const imgsWidth = (newGalleryItems.length + 1) * (sliceWidth + sliceMargin) + maxWidth;
				setContainerWidth(imgsWidth);
			}
		});
	};

	const updateLayout = () => {
		// the maximum width an image can be is the container width minus prev/next buttons width
		setMaxWidth(wrapperEl.current?.offsetWidth - 60);
	};

	// Calculate how much the image container needs to move over
	const calcLeft = (index, isAd = false) => {
		// the amount that the image can be moved to be left aligned
		let slideOver = index * (sliceWidth + sliceMargin);

		// calculate to center align images
		if (alignment === 'center') {
			const imgWidth = isAd
				? 300
				: index === totalImgCount
				? // static width if this is the CTA slide
				  sliceWidth
				: // else find the current porportional width
				scaledWidth[index] > maxWidth
				? maxWidth
				: scaledWidth[index];
			// add on amount to center align image
			slideOver = slideOver + imgWidth / 2 + sliceMargin * 2;
			if (slideOver < maxWidth / 2) {
				// since we start left aligned, images need to stay in place, i.e. no shifting,
				// until an image hits the center point
				slideOver = 0;
			} else {
				slideOver = `calc(50% - ${slideOver}px)`;
			}
		} else {
			// left aligned just needs to convert to a negative to slide over
			slideOver = slideOver >= 0 ? slideOver * -1 : 0;
		}

		setLeft(slideOver);
	};

	// determine if the photo text runs long and needs to have an option to expand up
	const expandButton = (index) => {
		const titleEl = titleRefs.current[index]?.current;
		const descriptionEl = descriptionRefs.current[index]?.current;
		let canExpand = false;

		// if the title is two or more lines
		if (titleEl != null && titleEl.scrollHeight >= titleLineHeight * 2) {
			// set description to only show one line
			setMultilineDescription(false);

			if (titleEl.scrollHeight >= titleLineHeight * 3) {
				// if the title overflows, show expand arrow
				canExpand = true;
			} else if (
				descriptionEl != null &&
				descriptionEl.scrollHeight > descriptionLineHeight
			) {
				// or if the description goes over one line show expand arrow
				canExpand = true;
			}
		} else if (descriptionEl != null) {
			// else title is only one line and there's a description

			// set description to show up to two lines
			setMultilineDescription(true);
			// if the description text overflows, show expand arrow
			if (titleEl != null) {
				// when there's a title, restrict to two lines
				if (descriptionEl.scrollHeight > descriptionLineHeight * 2) {
					canExpand = true;
				}
			} else if (descriptionEl.scrollHeight > descriptionLineHeight * 3) {
				// if there's no title then let the description run to 3 lines
				canExpand = true;
			}
		}

		setShowExpandButton(canExpand);
	};

	const prevClick = () => {
		// adjust the index reference if an ad slide is currently in place
		const index = adActive ? activeSlide + 1 : activeSlide;

		if (index !== 0) {
			updateSlide(index - 1);
		}
	};

	const nextClick = () => {
		if (activeSlide < totalImgCount) {
			updateSlide(activeSlide + 1);
		} else {
			// at the end of the gallery send to CTA gallery detail page
			window.location.href = nextGalleryInfo.uri;
		}
	};

	const updateSlide = (index, queryRefresh = false) => {
		// QUICK CHANGE for option for only next slide to click over
		// wrap all of below block in IF statement
		// if (index === activeSlide + 1 || index === activeSlide - 1 || queryRefresh)

		clearTimeout(pageviewTimeout);

		// when within 7 images of the end of the current selection,
		// query for more images if we haven't reached the end yet
		if (activeSlide > galleryItems.length - 7 && galleryItems.length < totalImgCount) {
			loadMoreImgs();
		}

		setExpanded(false);
		setAdActive(false);
		if (!queryRefresh) {
			// increment the number of clicks as long as this isn't just updating the gallery on a query fetch
			numOfClicks.current++;
		}

		// determine if we need to insert an interstitial ad
		if (numOfClicks.current >= adFrequency && index < totalImgCount) {
			// reset user click count
			numOfClicks.current = 0;
			let adIndex;
			// figure out placement of the ad based on the newly selected slide vs the previously selected slide
			if (index === activeSlide + 1) {
				// if clicking to the next slide, insert after the current slide
				adIndex = index;
			} else if (index < activeSlide) {
				// if clicking backwards one or more, set the newly selected slide as active and adjust ad to shift right after it
				adIndex = index + 1;
				setActiveSlide(index);
			} else {
				// if clicking a few forward, set the previous slide as active to make room for the ad
				adIndex = index;
				setActiveSlide(index - 1);
			}
			setAdSlideStyle({ left: adIndex * (sliceWidth + sliceMargin) });
			calcLeft(adIndex, true);
			setAdActive(true);
		} else {
			// otherwise just move on to the new slide
			expandButton(index);
			setActiveSlide(index);
			calcLeft(index);
			// set a delay for an analytics page view event
			setPageviewTimeout(setTimeout(pageView, 300));
		}
	};

	// page view event for analytics tracking
	const pageView = () => {
		// set the dataLayer for gallery info
		window.dataLayer = dataLayer?.gallery || window.dataLayer;
		if (typeof galleryPageviewEvent.current === 'function') galleryPageviewEvent.current();
		// reset the dataLayer object in case user clicks outside of the gallery widget to maintain proper tracking data
		window.dataLayer = dataLayer?.parent || window.dataLayer;
	};

	const isLastSlide = activeSlide + 1 === galleryItems.length;

	return (
		<div
			className={`gallery__img-wrapper  ${
				hasCover ? 'gallery__img-wrapper--with-cover' : ''
			}`}
			ref={wrapperEl}
		>
			<button
				className={`gallery__arrow gallery__arrow--left ${
					activeSlide === 0 && !adActive
						? 'gallery__arrow--inactive'
						: 'gallery__arrow--active'
				}`}
				onClick={prevClick}
				{...analyticsData}
				data-hook="gallery-prev-arrow"
			>
				<SvgImage image={chevronThin} className="gallery__arrow-icon" />
			</button>

			<button
				className={`gallery__arrow gallery__arrow--right ${
					isLastSlide && !hasNextGallery
						? 'gallery__arrow--inactive'
						: 'gallery__arrow--active'
				}`}
				onClick={nextClick}
				{...analyticsData}
				data-hook="gallery-next-arrow"
			>
				<SvgImage image={chevronThin} className="gallery__arrow-icon" />
			</button>

			<div className="gallery__imgs" style={{ left: left, width: containerWidth }}>
				<GalleryInterstitialAd
					active={adActive}
					style={adSlideStyle}
					widgetAdKeyword={widgetAdKeyword}
					isSlideBeforeInterstitial={numOfClicks.current === adFrequency - 1}
				/>

				{galleryItems.map((item, index) => {
					const width = parseInt(item?.image?.sourceWidth);
					const height = parseInt(item?.image?.sourceHeight);
					scaledWidth[index] = (width / height) * thumbnailHeight;
					const fullWidth = scaledWidth[index] > maxWidth ? maxWidth : scaledWidth[index];
					const calcHeight =
						scaledWidth[index] > maxWidth
							? (maxWidth * height) / width
							: thumbnailHeight;

					if (item.title) {
						titleRefs.current[index] = React.createRef();
					}

					if (item.caption) {
						descriptionRefs.current[index] = React.createRef();
					}

					return (
						index < totalImgCount && (
							<Fragment key={`gallery-${item.image.id}`}>
								<div
									className={`gallery__item ${
										activeSlide === index && !adActive
											? activeClassNames.slide
											: inactiveClassNames.slide
									}
								${activeSlide === index && adActive ? activeClassNames.adSlide : ''}
								${
									activeSlide === index + 1 || activeSlide === index - 1
										? 'gallery__item--inactive-adjacent'
										: ''
								}`}
									style={{
										width: activeSlide === index && !adActive ? fullWidth : ''
									}}
									data-hook="gallery-item"
								>
									<figure>
										<div
											className="gallery__figure-wrap"
											style={{ height: thumbnailHeight }}
										>
											<button
												className="gallery__thumb"
												style={{ height: calcHeight }}
												onClick={() => {
													if (index !== activeSlide || adActive) {
														updateSlide(index);
													}
												}}
												{...analyticsData}
												data-hook="gallery-item-thumb"
											>
												<GallerySlideImg
													src={item.image.uri}
													alt={item.title}
													height={thumbnailHeight}
													width={scaledWidth[index]}
													fullWidth={width}
													style={{ height: calcHeight }}
													root={wrapperEl.current}
													disableLazyLoadOnFirstImg={
														disableLazyLoadOnFirstImg && index === 0
													}
												/>

												<div
													className="gallery__count"
													data-hook="gallery-item-count"
												>
													{index + 1}/
													{Number(totalImgCount) > 500
														? '500+'
														: totalImgCount}
												</div>
												<div className="gallery__credit">
													{parseHtml(item.image.agency)}
												</div>
											</button>
										</div>

										<figcaption
											className={`gallery__box ${
												expanded && activeSlide === index
													? 'gallery__box--expanded'
													: ''
											}`}
										>
											{item.title && (
												<p
													className="gallery__title"
													style={{
														width: fullWidth - 20
													}}
													ref={titleRefs.current[index]}
												>
													{parseHtml(item.title)}
												</p>
											)}

											{item.caption && (
												<Fragment>
													<div
														className={`gallery__description ${
															item.title === ''
																? 'gallery__description--three-line'
																: multilineDescription
																? 'gallery__description--two-line'
																: 'gallery__description--single-line'
														}`}
														ref={descriptionRefs.current[index]}
														style={{
															width: fullWidth - 20
														}}
													>
														{parseHtml(item.caption)}
													</div>
													{authorBylineEnabled && (
														<WidgetAuthor authors={authors} />
													)}
													{timestampsEnabled && (
														<WidgetDate date={publishDate} />
													)}
													<button
														className={`gallery__expand-button ${
															showExpandButton
																? 'gallery__expand-button--active'
																: ''
														}`}
														onClick={() => {
															if (
																activeSlide === index &&
																!adActive &&
																showExpandButton
															) {
																setExpanded(!expanded);
															}
														}}
													>
														<SvgImage
															image={chevronThin}
															className="gallery__expand-arrow"
														/>
													</button>
												</Fragment>
											)}
										</figcaption>
									</figure>
								</div>
								{totalImgCount === index + 1 && hasNextGallery && (
									<GalleryCTA {...nextGalleryInfo} />
								)}
							</Fragment>
						)
					);
				})}
			</div>
		</div>
	);
};

GallerySlider.defaultProps = {
	hasCover: false,
	alignment: 'left',
	disableLazyLoadOnFirstImg: false
};

GallerySlider.propTypes = {
	nodes: array.isRequired,
	totalCount: number.isRequired,
	hasCover: bool,
	alignment: string,
	fetchMore: func.isRequired,
	widgetAdKeyword: string,
	nextGalleryInfo: object,
	analyticsData: object,
	authors: array,
	publishDate: string,
	timestampsEnabled: bool,
	authorBylineEnabled: bool,
	dataLayer: object,
	disableLazyLoadOnFirstImg: bool.isRequired
};

GallerySlider.displayName = 'GallerySlider';

export default GallerySlider;
