/**
 * @function customEventDispatcher
 * @description Create a custom event using the name
 * of your choice. Will also return back a ready made
 * function used to dispatch your event as needed.
 *
 * To test, you can use the following script:
 *
 * window.addEventListener('name_of_your_event', function(e) {
 *      console.log('name_of_your_event fired!');
 * });
 * @param {String} customEventName
 * @param {Boolean} [executeNow] if true triggers the dispatchEvent immediately, if false returns a function
 * @returns {Function}
 */
const customEventDispatcher = (customEventName, executeNow = false) => {
	if (typeof window !== 'undefined') {
		/* eslint-disable no-undef */
		/* disabling eslint to avoid no undef rule
		for custom event, which is part of the browser api */
		const customEvent = new CustomEvent(customEventName);
		/* eslint-enable no-undef */

		return executeNow
			? window.dispatchEvent(customEvent)
			: () => {
					window.dispatchEvent(customEvent);
			  };
	}

	return null;
};

export default customEventDispatcher;
