import { getThemeClassName } from '@/components/widgets/widgetHelpers';
import { string, object } from 'prop-types';

/**
 * @function getTemplateConfig
 * @description returns templating info
 * @param {String} widgetTemplate
 * @param {Object} [coverflowBackgroundImage]
 * @param {String} [pageTheme] theme class coming from the page level
 * @return {Object} object containing widget template values
 */
const getTemplateConfig = ({ widgetTemplate, coverflowBackgroundImage, pageTheme }) => {
	// if an overall page theme exists, that takes precedense over the theme set at the widget level
	const pageThemeClass = getThemeClassName(pageTheme);

	const templates = {
		SPONSORED_GALLERY_DARK: {
			hasAdCover: true, // whether to show an ad coverflow image
			hasCover: true, // whether a coverflow image exists in any capacity
			themeClass: pageThemeClass || getThemeClassName('DARK') // widget color theme class
		},
		SPONSORED_GALLERY_LIGHT: {
			hasAdCover: true,
			hasCover: true,
			themeClass: pageThemeClass || getThemeClassName('LIGHT')
		},
		GALLERY_DARK: {
			hasAdCover: false,
			hasCover: !!coverflowBackgroundImage,
			themeClass: pageThemeClass || getThemeClassName('DARK')
		},
		GALLERY_LIGHT: {
			hasAdCover: false,
			hasCover: !!coverflowBackgroundImage,
			themeClass: pageThemeClass || getThemeClassName('LIGHT')
		}
	};

	return templates[widgetTemplate] || templates.GALLERY_DARK;
};

getTemplateConfig.propTypes = {
	widgetTemplate: string.isRequired,
	coverflowBackgroundImage: object,
	pageTheme: string
};

getTemplateConfig.displayName = 'getTemplateConfig';

export default getTemplateConfig;
