import React from 'react';
import MpsAd from '@/components/mps/MpsAd';
import { string } from 'prop-types';
import './CoverflowAd.scss';

/**
 * @function CoverflowAd
 * @description Cover image ad for gallery widget
 * @param {Object} props
 * @param {String} props.widgetAdKeyword ad keyword
 * @return {React.ReactElement}
 */
const CoverflowAd = ({ widgetAdKeyword }) => (
	<div className="coverflow-ad">
		<MpsAd slot="cover" keywords={widgetAdKeyword} centered />
	</div>
);

CoverflowAd.propTypes = {
	widgetAdKeyword: string.isRequired
};

CoverflowAd.displayName = 'CoverflowAd';

export default CoverflowAd;
