import createAnalyticsString from '@/utils/createAnalyticsString';

/**
 * @function formatAuthors
 * @description formats authors array for use with analytics
 * @param {Array} authors
 * @returns {String}
 */
export const formatAuthors = (authors) => {
	return Array.isArray(authors)
		? authors
				.filter((author) => author?.fullName)
				.map(({ fullName }) => createAnalyticsString(fullName))
				.join(', ')
		: '';
};

/**
 * @function formatCategoriesForAnalytics
 * @description formats categories for use with analytics
 * @param {Array} categories
 * @returns {String}
 */
export const formatCategoriesForAnalytics = (categories) => {
	return Array.isArray(categories)
		? categories
				.filter((cat) => cat?.displayName)
				.map(({ displayName }) => createAnalyticsString(displayName))
				.join(', ')
		: '';
};
