import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { usePageContext } from '@/components/layout/page/PageContext';
import GallerySlider from './GallerySlider';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import chevronThin from '@/images/chevron-thin.svg';
import SvgImage from '@/components/icons/SvgImage';
import Thumb from '@/components/thumb/Thumb';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import Translate from '@/components/i18n/Translate';
import CoverflowAd from '@/components/ads/CoverflowAd';
import parseHtml from '@/utils/parseHtml';
import format from 'date-fns/format';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { useResizePageObserver } from '@/components/layout/page/ResizePageObserver';
import { formatCategoriesForAnalytics, formatAuthors } from '@/components/analytics/helpers';
import breakpointType from '@/utils/breakpointType';
import getTemplateConfig from './getTemplateConfig';
import { object, string, array, bool, number } from 'prop-types';
import { useWidgetContext } from '@/components/widgets/WidgetContext';
import {
	prepareAllGalleryTypes,
	mapGalleryFolderToGallery
} from '@/components/widgets/widgetHelpers';
import './Gallery.scss';

/**
 * @function GalleryWidget
 * @description creates a photo gallery widget
 * @param {Object} props
 * @param {String|null} [props.id] widget ID
 * @param {Array} props.contentList
 * @param {String|null} [props.header]
 * @param {String|null} [props.widgetAdKeyword]
 * @param {String|null} [props.brandedTextOverride]
 * @param {String|null} [props.advertiserLogoOverride]
 * @param {String|null} [props.brandingType]
 * @param {Object} props.criteria for analytics
 * @param {Boolean} props.isCurated for analytics
 * @param {String|null} [props.position] for analytics
 * @param {String} props.sectionType used to determine slide alignment
 * @param {String|null} [props.widgetTemplate] for analytics
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Object|Null} [props.coverflowBackgroundImage]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {String|null} [props.pageTheme]
 * @param {Number} props.sectionIndex
 * @return {React.ReactElement}
 */
const GalleryWidget = ({
	id,
	contentList,
	header,
	widgetAdKeyword,
	brandedTextOverride,
	advertiserLogoOverride,
	brandingType,
	criteria,
	isCurated,
	position,
	sectionType,
	widgetTemplate,
	coverflowBackgroundImage,
	timestampsEnabled,
	authorBylineEnabled,
	sectionIndex,
	pageTheme
}) => {
	const { uppercaseEdition } = usePageContext();
	const [dataLayer, setDataLayer] = useState({});
	const { width } = useResizePageObserver();
	const type = breakpointType(width);
	const { isThumbLazyLoadDisabled } = useWidgetContext();

	const template = getTemplateConfig({
		widgetTemplate,
		coverflowBackgroundImage,
		pageTheme
	});

	const {
		galleryId,
		galleryTitle,
		nextGalleryInfo,
		query,
		queryName,
		linkType,
		variables
	} = prepareAllGalleryTypes(contentList);

	// Query the Gallery data
	// use pollInterval to update query every 30s to account for any deletions or reordering
	const { data, error, fetchMore } = useQuery(query, {
		variables: {
			...variables,
			edition: uppercaseEdition,
			skip: 0,
			limit: 24,
			pollInterval: 30000
		}
	});

	const preGallery = data?.[queryName];
	const gallery = useMemo(() => mapGalleryFolderToGallery(linkType, preGallery), [
		linkType,
		preGallery
	]);

	useEffect(() => {
		if (galleryId && gallery) {
			// set up dataLayer information for the widget
			let galleryData = {
				pageInfo: {
					siteSection: 'photos',
					pageType: 'gallery-detail'
				},
				contentInfo: {
					title: createAnalyticsString(galleryTitle),
					publishDate: gallery?.publishDate
						? format(new Date(gallery?.publishDate), 'yyyy-dd-MM')
						: '',
					authors: formatAuthors(gallery?.authors),
					pagewidgetType: 'arrivals_gallery_detail',
					originalUrl: `www.eonline.com${gallery?.uri}`,
					categories: formatCategoriesForAnalytics(gallery?.categories)
				},
				sponsoredInfo: {
					advertiser: widgetAdKeyword
				}
			};

			let parentData = {};

			if (typeof window !== 'undefined') {
				// preserve original dataLayer info
				parentData = window.dataLayer || {};
				// merge in any missing data from the original page dataLayer object
				galleryData = { ...parentData, ...galleryData };
			}
			setDataLayer({ parent: parentData, gallery: galleryData });
		}
	}, [gallery, galleryId, galleryTitle, widgetAdKeyword]);

	if (error) {
		console.log(error.message);
		return null;
	}
	if (!data) return null;
	const nodes = gallery?.galleryitems?.nodes;
	if (!nodes || !nodes.length) return null;

	const galleryUri = gallery.uri;
	const ads = {
		adKeywords: widgetAdKeyword
	};
	const mobileImg = gallery.thumbnail || nodes[0].image;

	const analyticsData = {
		'data-widget-type': 'gallery-widget',
		'data-widget-template': widgetTemplate,
		'data-widget-position': position,
		'data-widget-data-type': 'photos'
	};

	return (
		<section
			className={`widget widget__gallery widget--with-border ${template.themeClass}`}
			data-hook="gallery-widget"
			data-tb-region={`widget__gallery ${widgetTemplate} ${position}`}
		>
			{/* only show sponsor logo in header in the desktop version;
			logo gets added within the title & link text block below for tablet & mobile */}
			{(header || (widgetAdKeyword && brandingType && type === 'desktop')) && (
				<div className="gallery__header">
					<WidgetHeader
						title={header}
						widgetAdKeyword={type === 'desktop' ? widgetAdKeyword : null}
						brandedTextOverride={brandedTextOverride}
						advertiserLogoOverride={advertiserLogoOverride}
						brandingType={brandingType}
					/>
				</div>
			)}

			<div
				className={`gallery__border ${
					template.hasCover
						? 'gallery__border--with-cover'
						: 'gallery__border--without-cover'
				}`}
			>
				{/* coverflow image inputted by the user */}
				{template.hasCover && !template.hasAdCover && (
					<div className="gallery__cover" data-hook="gallery-cover">
						<Thumb
							thumbnail={coverflowBackgroundImage}
							title={header}
							width="300"
							type="1x2"
							withLink={false}
						/>
					</div>
				)}

				{/* coverflow image provided by sponsor */}
				{template.hasAdCover && widgetAdKeyword && (
					<div className="gallery__cover" data-hook="gallery-cover">
						<CoverflowAd widgetAdKeyword={widgetAdKeyword} />
					</div>
				)}

				{/* Desktop gallery view */}
				{type === 'desktop' && (
					<GallerySlider
						nodes={nodes}
						totalCount={gallery.galleryitems.totalCount}
						hasCover={template.hasCover}
						alignment={sectionType === 'FULL' ? 'center' : 'left'}
						widgetAdKeyword={widgetAdKeyword}
						fetchMore={fetchMore}
						nextGalleryInfo={nextGalleryInfo}
						authors={gallery?.authors}
						publishDate={gallery?.publishDate}
						authorBylineEnabled={authorBylineEnabled}
						timestampsEnabled={timestampsEnabled}
						analyticsData={analyticsData}
						dataLayer={dataLayer}
						disableLazyLoadOnFirstImg={sectionIndex === 0}
					/>
				)}

				{/* Mobile / Tablet view */}
				{(type === 'mobile' || type === 'tablet') && (
					<div
						className={`gallery__mobile-link-block ${
							template.hasCover
								? 'gallery__mobile-link-block--with-cover'
								: 'gallery__mobile-link-block--without-cover'
						}`}
						data-tb-region-item
					>
						<div className="gallery__mobile-img">
							<Thumb
								href={galleryUri}
								thumbnail={mobileImg}
								title={galleryTitle}
								width="414"
								width2x="828"
								type="16x9"
								withLink={true}
								analyticsData={analyticsData}
								lazyLoad={!isThumbLazyLoadDisabled}
							/>
						</div>
						<button
							className="gallery__mobile-text"
							onClick={() => {
								window.location.href = galleryUri;
							}}
							{...analyticsData}
						>
							<div className="gallery__mobile-text-wrap">
								<h4>
									<Translate word="VIEW_GALLERY" />
								</h4>
								<div className="gallery__mobile-title">
									{parseHtml(galleryTitle)}
								</div>
								{ads.adKeywords && (
									<SponsoredBy
										brandingType={brandingType}
										ads={ads}
										displayText={brandedTextOverride}
										advertiserLogoOverride={advertiserLogoOverride}
										className="is-header"
									/>
								)}
								<SvgImage image={chevronThin} className="gallery__arrow-link" />
							</div>
						</button>
					</div>
				)}
			</div>
		</section>
	);
};

GalleryWidget.propTypes = {
	id: string,
	contentList: array.isRequired,
	header: string,
	widgetAdKeyword: string,
	brandedTextOverride: string,
	advertiserLogoOverride: string,
	brandingType: string,
	criteria: object.isRequired,
	isCurated: bool.isRequired,
	position: string,
	sectionType: string,
	widgetTemplate: string,
	coverflowBackgroundImage: object,
	timestampsEnabled: bool,
	authorBylineEnabled: bool,
	pageTheme: string,
	sectionIndex: number.isRequired
};

GalleryWidget.displayName = 'GalleryWidget';

export default GalleryWidget;
